export enum ROLE_ACCESS {
  Owner = 'Owner',
  Viewer = 'Viewer',
  Editor = 'Editor',
  Revoked = 'Revoked',
  Requested = 'Requested',
}
export enum USER_PLAN {
  Business = 'Business',
  Education = 'Education',
  Personal = 'Personal',
  Enterprise = 'Enterprise',
  Verified = 'Verified',
}
export enum MAXIMUM_STORAGE {
  Education = 2,
  Personal = 2,
  Verified = 10,
  Business = 256,
}

export const OWNER_ROLE = {
  label: 'Chủ sở hữu',
  value: ROLE_ACCESS.Owner,
};
export const VIEWER_ROLE = {
  label: 'Thành viên',
  value: ROLE_ACCESS.Viewer,
};
export const EDITOR_ROLE = {
  label: 'Biên tập viên',
  value: ROLE_ACCESS.Editor,
};
export const REVOKED_ROLE = {
  label: 'Hủy quyền',
  value: ROLE_ACCESS.Revoked,
};

export const VIEW_ACTION = 'View';
export const EDIT_ACTION = 'Edit';

export type TRoleAccess = `${ROLE_ACCESS}`;
