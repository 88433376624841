import Image from 'next/image';

import { Button } from '@mui/material';
import BaseLayout from 'layouts';
import { useRouter } from 'next/router';
import { FilledRefreshCircle } from '../components/elements/svgIcon/filled';
import { OutlinedNewArrorRight } from '../components/elements/svgIcon/outlined';
import { ZALO_OA_SUPPORT } from '../configs/externalLink';
import { PATH_NAME } from '../configs/pathName';
import { State404Icon } from '../constant/imgImport';
import { NextPageWithLayout } from './page';

const NotFound: NextPageWithLayout = () => {
  const router = useRouter();
  return (
    <BaseLayout>
      <div className="mx-auto flex h-screen w-full max-w-container items-center justify-center">
        <div className="flex flex-col justify-center px-4">
          <Image
            src={State404Icon}
            alt="not found"
            width={'200'}
            height={'200'}
          />
          <p className="text-center text-2xl font-medium leading-[167%] tracking-[-0.3px] text-Text/Lightbackground/Secondary">
            Oopss. Trang bạn đang tìm kiếm không tồn tại.
          </p>
          <p className="text-center text-2xl font-medium leading-[167%] tracking-[-0.3px] text-Text/Lightbackground/Secondary">
            Vui lòng trở lại trang chủ hoặc liên hệ quản trị viên để được giúp
            đỡ.
          </p>
          <div className="mt-20 flex w-full flex-col items-center justify-center gap-6 md:flex-row">
            <Button
              onClick={() => router.push(PATH_NAME.LIBRARY)}
              variant="medium_emphasis_v2"
              className="!min-w-[200px] !text-base !font-medium !leading-4"
              startIcon={<OutlinedNewArrorRight />}
            >
              Trở về trang chủ
            </Button>
            <Button
              onClick={() => window.open(ZALO_OA_SUPPORT)}
              variant="high_emphasis_v2"
              className="!min-w-[200px] !text-base !font-medium !leading-4"
              startIcon={<FilledRefreshCircle />}
            >
              Liên hệ hỗ trợ
            </Button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default NotFound;
